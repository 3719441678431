<template>
  <div class="container-fluid">


    <div class="consolidated-statement-by-group mt-4 mb-4">
      <h4 class="text-center mb-4">Сводная ведомость по группам</h4>

      <div class="container mt-4">




        <!--        <div class="row mt-4">-->
        <!--          <label class="col-md-3 col-form-label">Язык-->
        <!--            обучения</label>-->
        <!--          <div class="col-md-9">-->
        <!--            <select class="form-control form-select" id="create_language"-->
        <!--                    @input="changeStatement('language_id', $event)">-->
        <!--              <option v-for="(item, index) in consolidatedStatement_form.languages"-->
        <!--                      :value="item.id"-->
        <!--                      :key="index">{{ item.name }}-->
        <!--              </option>-->
        <!--            </select>-->
        <!--          </div>-->
        <!--        </div>-->

        <div class="row mt-4">
          <label class="col-md-3 col-form-label">Курс</label>
          <div class="col-md-9">
            <select class="form-control form-select"
                    @input="changeStudyCourse($event)">
              <option selected value="0">Выберите курс</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
            </select>
          </div>
        </div>


        <div class="row mt-4">
          <label class="col-md-3 col-form-label">Уровень
            обучения</label>
          <div class="col-md-9">

            <div v-if="consolidatedStatement_form.statementInfos.study_course == 0">
              <p><strong style="color: red">Курс не выбран</strong></p>
            </div>
            <div v-else>
              <select class="form-control form-select"
                      @input="changeStudyLevel($event)">
                <option v-for="(item, index) in [{id: 0, name: 'Выберите уровень обучения'}, ...consolidatedStatement_form.studyLevel]"
                        :value="item.id"
                        :key="index">{{ item.name }}
                </option>
              </select>
            </div>


          </div>
        </div>

        <div class=" row mt-4">
          <label class="col-md-3 col-form-label">Образовательная программа</label>
          <div class="col-md-9">
            <div v-if="studyLevelId == 0">
              <p><strong style="color: red">Уровень обучения не выбран</strong></p>
            </div>
            <div v-else>
              <select class="form-control form-select"
                      @input="changeStatement('education_program_id', $event)">
                <option v-for="(item, index) in [{education_speciality_name: 'Выберите ОП', language_name: 'язык', admission_year:'год' },
                {id: 0, education_speciality_name: 'Все', language_name: 'язык', admission_year:'год' }, ...consolidatedStatement_form.educationPrograms]"
                        :value="item.id"
                        :key="index">{{ item.education_speciality_code }} {{ item.education_speciality_name }} - {{ item.language_name }} - {{ item.admission_year }}
                </option>
              </select>
            </div>

          </div>
        </div>




        <div class="row mt-4">
          <div class="col-md-3">
            <p>Учебный год</p>
          </div>
          <div class="col-md-9">
            <select class="form-control form-select"
                    @input="changeStatement( 'sp_academic_year_id', $event)">
              <option
                  v-for="(item, index) in [{id: 0, name: 'Выберите учебный год'}, ...consolidatedStatement_form.academicYear]"
                  :value="item.id"
                  :selected="item.id == 17"
                  :key="index" disabled>{{ item.name }}
              </option>
            </select>
          </div>
        </div>


        <div class="row mt-4">
          <label class="col-md-3 col-form-label">Семестр</label>
          <div class="col-md-9">
            <select class="form-control form-select"
                    @input="changeStatement('semester', $event)">
              <option selected value="0">Выберите семестр</option>
              <option value="1">Осень</option>
              <option value="2">Весна</option>
            </select>
          </div>
        </div>


        <!--        <div class="row mt-4">-->
        <!--          <label class="col-md-3 col-form-label">Тип</label>-->
        <!--          <div class="col-md-9">-->
        <!--            <select class="form-control form-select"-->
        <!--                    @input="changeStatement('statement_type', $event)">-->
        <!--              <option selected>Выберите тип</option>-->
        <!--              <option value="vsk1">ВСК1</option>-->
        <!--              <option value="vsk2">ВСК2</option>-->
        <!--              <option value="exam">Экзамен</option>-->
        <!--              <option value="total">Итоговая</option>-->
        <!--              <option value="all">Все</option>-->
        <!--            </select>-->
        <!--          </div>-->
        <!--        </div>-->

      </div>

      <div class="col-md-12 mt-4 text-center" style="min-height: 120px">
        <!--        <button type="button" class="btn btn-primary" @click="formStatement" :disabled="consolidatedStatement_form.statementInfos.semester == 0">-->
        <!--          <span v-if="formStatementBtnDisabled" class="spinner-grow spinner-grow-sm" role="status"-->
        <!--                aria-hidden="true"></span>-->
        <!--          {{ formStatementBtnDisabled ? 'Идет формирование' : 'Сформировать' }}-->
        <!--        </button>-->

        <button type="button" class="btn btn-primary" @click="formStatement" :disabled="consolidatedStatement_form.statementInfos.semester == 0">
          Сформировать
        </button>


      </div>



      <div class="col-md-12" v-if="consolidatedStatement_form.consolidatedStatementData.length != 0">






          <!-- table start -->
          <div class="table-responsive mt-4">
            <table class="table table-striped table-bordered border-dark small_font">

              <thead>
              <tr>
                <th scope="col" rowspan="2">№</th>
                <th scope="col" rowspan="2">Специальность, группа</th>
                <th scope="col" rowspan="2">Всего студентов</th>
                <th scope="col" colspan="5">Сдали</th>
                <th scope="col" colspan="4">Получили "F"</th>
                <th scope="col" rowspan="2">Абсолютная успеваемость, %</th>
                <th scope="col" rowspan="2">Качество, %</th>
                <th scope="col" rowspan="2">Средний балл</th>

              </tr>
              <tr>


                  <th scope="col">по всем предметам</th>
                  <th scope="col">только на "A", "A-"</th>
                  <th scope="col">на "B", "B+", "B-", "C+"</th>
                  <th scope="col">на "C", "C-"</th>
                  <th scope="col">только на "D", "D+"</th>
                  <th scope="col">Всего</th>
                  <th scope="col">одну</th>
                  <th scope="col">две</th>
                  <th scope="col">три и более</th>



              </tr>
              </thead>
              <tbody>

              <tr v-for="(consolidatedStatementGroups,key, index) in consolidatedStatement_form.consolidatedStatementData" :key="key">

                <th scope="row">{{ index + 1 }}</th>



                <td>{{ consolidatedStatementGroups.group_name }}</td>
                <td>{{ Object.keys(consolidatedStatementGroups.students).length }}</td>







              </tr>

              </tbody>
            </table>
            <!-- table end -->






        </div>


      </div>



    </div>

  </div>

</template>

<script>
import {mapGetters, mapActions, mapMutations, mapState} from "vuex";
import axios from 'axios';
export default {
  name: "ConsolidatedStatementByGroup",
  data() {
    return {
      filteredEducationPrograms: [],
      studyLevelId: 0,
      studyCourse: 0,
      formStatementBtnDisabled: false
    }
  },
  computed: {
    ...mapState('consolidatedStatement', ['consolidatedStatement_form']),
  },

  methods: {
    ...mapActions('consolidatedStatement', ['GET_STUDY_LEVEL_NAMES', 'GET_EDUCATION_PROGRAM_NAMES', 'GET_SP_ACADEMIC_YEAR','GET_CONSOLIDATED_STATEMENT']),
    ...mapMutations('consolidatedStatement', ['SET_STATEMENT_DATA', 'SET_STUDY_COURSE']),

    changeStatement(property, e, val = 'value') {
      let value = e.target[val]
      console.log(value, "value")
      this.SET_STATEMENT_DATA({property, value})
    },
    changeStudyLevel(e){

      this.studyLevelId = e.target.value
      console.log(this.studyLevelId, 'studyLevelId')

      this.GET_EDUCATION_PROGRAM_NAMES(this.studyLevelId)
      //this.filteredEducationPrograms = this.consolidatedStatement_form.educationPrograms.filter(i => i.study_level_id == this.studyLevelId)
    },
    changeStudyCourse(e){

      this.studyCourse = e.target.value
      console.log(this.studyCourse, 'studyCourse')
      this.SET_STUDY_COURSE(this.studyCourse)


    },
    formStatement() {
      this.GET_CONSOLIDATED_STATEMENT()
    }
  },

  async mounted() {
    await this.GET_SP_ACADEMIC_YEAR()
    await this.GET_STUDY_LEVEL_NAMES()
    //await this.GET_EDUCATION_PROGRAM_NAMES()

  }
}
</script>

<style scoped>
.small_font {
  font-size: 10px;
}
.danger {
  background-color:#FF8080;
}
</style>